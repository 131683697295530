<template>
	<div class="app-slider" :class="{ 'app-slider--medium': medium, 'usual-arrow': usualArrow }">
		<div class="splide" ref="slider">
			<div class="splide__slider">
				<div class="splide__track">
					<div class="splide__list" v-if="list.length">
						<div v-for="(item, idx) in list" :key="idx" class="splide__slide">
							<slot :item="item" :medium="medium"></slot>
						</div>
					</div>
				</div>
				<app-button
					key="1"
					class="app-slider__button-previous"
					width="32"
					height="32"
					flex
					radius="100"
					shadow="medium"
					theme="white"
					@click="previous"
				>
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7 1L1 7L7 13"
							stroke="#8E9195"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</app-button>
				<app-button
					key="2"
					class="app-slider__button-next"
					width="32"
					flex
					height="32"
					radius="50"
					shadow="medium"
					theme="white"
					@click="next"
				>
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M1 13L7 7L1 1"
							stroke="#8E9195"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</app-button>
			</div>
		</div>
	</div>
</template>
<script>
import AppButton from "./AppButton";

const SplideJS = () => import("@splidejs/splide");
import "@splidejs/splide/dist/css/splide-core.min.css";
import "../../assets/styles/components/app-slider.scss";

export default {
	name: "AppSlider",
	components: {
		AppButton,
	},
	props: {
		list: {
			type: Array,
			required: true,
		},
		medium: {
			type: Boolean,
			default: false,
		},
		slideCount: {
			type: [String, Number],
			default: 4,
		},
		usualArrow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			cardsSlider: null,
			showPreviousArrow: false,
			showNextArrow: false,
		};
	},

	computed: {
		count() {
			if (this.isMobile) {
				return 1;
			}
			if (this.isDesktopSmall) {
				return this.medium ? 2 : 3;
			}
			return this.medium ? 3 : this.slideCount;
		},

		options() {
			return {
				rewind: true,
				drag: this.list.length > this.count,
				perPage: 4,
				perMove: 3,
				gap: 15,
				arrows: false,
				pagination: false,
				autoplay: false,
				interval: 4000,
				breakpoints: {
					1240: {
						gap: 15,
					},
					1100: {
						perPage: 3,
						gap: 15,
					},
					900: {
						perPage: 2,
						gap: 15,
					},

					550: {
						perPage: 1,
					},
				},
			};
		},
	},
	methods: {
		checkArrows(idx) {
			this.showPreviousArrow = idx > 0;
			this.showNextArrow = (this.isMobile ? idx : idx + this.count - 1) < this.list.length - 1;
		},
		next() {
			if (this.cardsSlider) {
				this.cardsSlider.go("+1");
			}
		},
		previous() {
			if (this.cardsSlider) {
				this.cardsSlider.go("-1");
			}
		},
		async init() {
			const refSlider = this.$refs["slider"];
			const Splide = await SplideJS();
			const slider = new Splide.default(refSlider, this.options);

			slider.mount();
			this.checkArrows(0);
			slider.on("move", (newIndex) => {
				this.checkArrows(newIndex);
				this.$emit("select", { index: newIndex });
			});
			this.cardsSlider = slider;
		},
	},
	mounted() {
		this.init();
	},
	watch: {
		list() {
			this.init();
		},
	},
	beforeDestroy() {
		if (this.cardsSlider) {
			const cardsSlider = this.cardsSlider;
			setTimeout(() => {
				cardsSlider.destroy();
			}, 150);
		}
	},
};
</script>

<style lang="scss">
.app-slider {
	&__button {
		&-next,
		&-previous {
			top: 55% !important;
			svg {
				width: 6px;
				height: 12px;
			}
		}
	}
}
@media (max-width: 600px) {
	.app-slider__button-next {
		display: none;
	}

	.app-slider__button-previous {
		display: none;
	}
}
</style>
