<template>
	<component
		class="a-btn"
		:class="className"
		:style="style"
		:type="type"
		:is="tag"
		@click="activate"
		v-bind="$attrs"
		v-on="listeners"
		:disabled="checkDisableStatus"
	>
		<slot v-if="!loading" />
		<div class="a-btn__loading" v-else>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</component>
</template>
<script>
import "../../assets/styles/components/app-button.scss";
export default {
	name: "AppButton",
	props: {
		storageType: {
			type: String,
		},
		activateOnClick: {
			type: Boolean,
			default: false,
		},
		freezeActivate: {
			type: Boolean,
			default: false,
		},
		freezeId: {
			type: String,
		},
		freezeTime: {
			type: Number,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: String,
			default: "button",
		},
		sides: {
			type: [Number, String],
			default: 0,
		},
		height: {
			type: [Number, String],
			default: 50,
		},
		width: {
			type: [Number, String],
		},
		radius: {
			type: [Number, String],
			default: 10,
		},
		fontSize: {
			type: [Number, String],
			default: 18,
		},
		weight: {
			type: [Number, String],
			default: 600,
		},
		theme: {
			type: String,
			default: "default.vue",
		},
		disabled: {
			type: Boolean,
		},
		textColor: {
			type: String,
		},
		outline: {
			type: Boolean,
		},
		noFocus: {
			type: Boolean,
		},
		type: {
			type: String,
			default: "button",
		},
		shadow: {
			type: String,
		},
		flex: {
			type: Boolean,
		},
	},
	data: () => ({
		freezeStatus: false,
	}),
	computed: {
		checkDisableStatus() {
			return Boolean(this.disabled || this.freezeStatus);
		},
		className() {
			const name = "a-btn--";
			const className = [
				name + "sd-" + this.sides,
				name + "rd-" + this.radius,
				name + "fn-" + this.fontSize,
				name + "wg-" + this.weight,
				name + "hg-" + this.height,
				name + "th-" + this.theme,
			];
			if (this.flex) {
				className.push(name + "fl");
			}
			if (this.width) {
				className.push(name + "wd");
			}
			if (this.outline) {
				className.push(name + "line");
			}
			if (this.textColor) {
				className.push(name + "cl-" + this.textColor);
			}
			if (this.noFocus) {
				className.push(name + "nf");
			}
			if (this.shadow) {
				className.push(name + "shadow-" + this.shadow);
			}
			return className;
		},
		style() {
			const styles = {};
			if (this.width) {
				styles["--wd"] = this.width + "px";
			}
			return styles;
		},
		listeners() {
			if (this.disabled) return {};

			return {
				click: (event) => this.$emit("click", event),
			};
		},
	},

	methods: {
		storage() {
			return this.storageType === "session" ? sessionStorage : localStorage;
		},
		freezeButton() {
			if (this.freezeTime > 0 && this.freezeId) {
				this.storage().setItem(`FreezeButton_${this.freezeId}`, new Date().getTime() + this.freezeTime * 1000);
				this.freezeStatus = true;
			}
			this.checkVisibility();
		},
		unFreeze() {
			let time = this.storage().getItem(`FreezeButton_${this.freezeId}`)
				? Number(this.storage().getItem(`FreezeButton_${this.freezeId}`))
				: 0;
			if (Number(time) <= new Date().getTime()) {
				this.freezeStatus = false;
				this.storage().removeItem(`FreezeButton_${this.freezeId}`);
			} else {
				this.freezeStatus = true;
			}
		},
		checkVisibility() {
			if (this.storage().getItem(`FreezeButton_${this.freezeId}`)) {
				this.unFreeze();
				window.requestAnimationFrame(this.checkVisibility);
			} else {
				this.freezeStatus = false;
			}
		},
		activate() {
			if (this.activateOnClick) {
				this.freezeButton();
			}
		},
	},

	mounted() {
		this.unFreeze();
		this.checkVisibility();
	},

	watch: {
		freezeActivate(val) {
			if (val) {
				this.freezeButton();
			}
		},
	},
};
</script>

