<template>
	<div>
		<div class="card">
			<div class="card__photo" :class="{ overlay: cardIcon }">
				<template v-if="logo">
					<img :src="logo" alt="" />
				</template>
				<template v-else>
					<img src="@/assets/images/svg/logo-grey.svg" alt="" />
				</template>

				<!-- <img :src="coverImage" v-if="cardIcon" :alt="title"> -->
				<!-- <img :src="image" :alt="title" v-else> -->
				<div class="card__photo-icon" :to="link" @click="$emit('openVideo')" v-if="cardIcon">
					<img src="../../assets/icons/play.svg" alt="" />
				</div>
			</div>

			<AppText size="18" weight="500" class="mb-20 font_Hmedium">
				{{ title }}
			</AppText>
			<div class="flex-1">
				<AppText max-lines="6" size="14" weight="400" lineHeight="17" class="mb-30 color_grey">
					{{ description }}
				</AppText>
			</div>
			<div class="nd_progres_bar mb-30">
				<AppText size="14" weight="500" class="mb-10 font_Hmedium"> {{ $t("voted") }}: </AppText>
				<div class="progress_wrapp">
					<div class="progress_block" :style="{ width: item.zaProcent + '%' }"></div>
					<div class="progress_add" :style="{ width: item.protivProcent + '%' }"></div>
				</div>
				<div class="progres_num">
					<AppText size="13" weight="500" class="font_Hmedium"> {{ item.zaProcent }}% </AppText>
					<AppText size="13" weight="500" class="font_Hmedium"> {{ item.protivProcent }} % </AppText>
				</div>
			</div>
			<template v-if="item.hasVoted">
				<AppText
					size="14"
					weight="500"
					class="font_Hmedium"
					:class="{
						'color-text-success2': item.hasVoted === statuses.FOR,
						'color-text-red': item.hasVoted === statuses.AGAINST,
					}"
					>Вы проголосовали “{{ statusesNames[item.hasVoted] }}”
				</AppText>
			</template>
			<template v-else>
				<div class="d-flex justify-content-between">
					<app-button
						@click="vote(1)"
						theme="main"
						sides="14"
						fontSize="14"
						radius="12"
						height="40"
						class="nd_btn_progress mr-15 w-100 font_Hsemibold"
					>
						<img src="../../assets/images/ok.svg" width="20" height="20" class="mr-10" alt="" />
						За
					</app-button>
					<app-button
						@click="vote(2)"
						theme="danger-light"
						sides="14"
						fontSize="14"
						radius="12"
						height="40"
						class="nd_btn_progress w-100 font_Hsemibold"
					>
						<img src="../../assets/images/dis_like.svg" width="20" height="20" class="mr-10" alt="" />
						Против
					</app-button>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import AppText from "./AppText";
import AppButton from "./AppButton";
import "../../assets/styles/components/app-card.scss";
import { voteStatusNames, voteStatuses } from "@/static/statusIds";

export default {
	name: "AppCard",
	components: { AppButton, AppText },
	props: {
		cover: {
			type: String,
			default: "",
		},
		url: {
			type: String,
			default: "",
		},
		image: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		date: {
			type: String,
			default: "",
		},
		cardIcon: {
			type: Boolean,
			default: false,
		},
		btn: {
			type: Boolean,
			default: false,
		},
		link: {
			type: String,
			default: "",
		},
		instructions: {
			type: Boolean,
			default: false,
		},
		downloadTitle: {
			type: String,
			default: "",
		},
		downloadPath: {
			type: String,
			default: "",
		},
		logo: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
		item: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
			statusesNames: voteStatusNames,
			statuses: voteStatuses,
		};
	},
	methods: {
		vote(status) {
			console.log("vote", status);
			this.$emit("onVote", status);
		},
	},
	computed: {
		coverImage() {
			return this.baseUrl + this.cover;
		},
	},
};
</script>

<style lang="scss" scoped>
.card__photo {
}

.progress_wrapp {
	width: 100%;
	height: 16px;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	margin-bottom: 10px;

	.progress_block {
		position: absolute;
		width: 70%;
		height: 100%;
		left: 0;
		top: 0;
		background: linear-gradient(270deg, #0a67a2 -22.58%, #13b2e4 117.5%);
		border-radius: 8px 0 0 8px;
	}

	.progress_add {
		position: absolute;
		width: 30%;
		height: 100%;
		right: 0;
		top: 0;
		background: #fb6161;
		border-radius: 0 8px 8px 0;
	}
}

.progres_num {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.justify-content-between {
	justify-content: space-between;
}

.nd_btn_progress {
	display: flex;
	align-items: center;
	justify-content: center;
}

.card {
	display: flex;
	flex-direction: column;
	height: 100%;
}
</style>
